.customer-item-list { margin: 0 0 -1.5rem -1.5rem; }
.customer-item { width: 25%; padding-left: 1.5rem; margin-bottom: 1.5rem; }
.customer-item-inner { text-align: center; display: block; text-decoration: none; }
.customer-item-img { width: 100%; margin-bottom: 1rem; height: 240px; overflow: hidden; }
.customer-item-img img { min-width: 100%; min-height: 100%; max-width: none; transition: transform 0.2s ease-out; }
.customer-item-title { @extend .h3; color: $darkBlue; margin-bottom: 0.3em; font-weight: 400; font-size: 1.3rem; }
.customer-item-content { font-size: 1rem; text-decoration: none; font-size: 0.9rem; }


a.customer-item-inner {
	.customer-item-title { text-decoration: underline; }

	&:hover, &:active, &:focus {
		.customer-item-title { text-decoration: none; }
		.customer-item-img img { @include scale(1.05); }
	}
}

@media screen and (max-width: $md) {
	.customer-item { width: 33.33333%; }
}


@media screen and (max-width: $sm) {
	.customer-item { width: 50%; }
}

@media screen and (max-width: $xs) {
	.customer-item { width: 100%; }
}
