a {
	@include transition(color 0.1s);
}

.btn {
	@include transition(all 0.25s ease-in);
}

.content:after {
	@include transition(opacity 0.5s ease-in);
}

.main-nav {
	@include transition(all 0.25s ease-in);
}

.main-nav-link {
	@include transition(color 0.25s ease-in);
}

.menu-trigger {
	@include transition(right 0.25s ease-in);
}

.header-claim {
	@include transition(all 0.25s ease-in);
}

.timeline-item-inner {
	@include transition(all 0.3s ease-in);
}

.timeline-item:after {
	@include transition(all 0.15s ease-in 0.15s);
}

.item-product {
	@include transition(all 0.25s ease-in);
}

.item-product-head {
	@include transition(transform 0.2s ease-in);
}

.item-product-number {
	@include transition(transform 0.2s ease-in);
}

.item-news-img img {
	@include transition(transform 0.2s ease-in);
}

.article-item-img img {
	@include transition(transform 0.3s ease-in);
}

.article-item-head:after {
	@include transition(background 0.2s ease-in);
}

.article-item-img:after {
	@include transition(background 0.2s ease-in);
}

.faq-head {
	@include transition(background 0.2s ease-in);
}

.faq-head:after {
	@include transition(transform 0.2s ease-in);
}

.map-region {
	@include transition(fill 0.2s ease-in);
}

.map-region-city {
	@include transition(color 0.2s ease-in);
}

.map-region-city:after, .map-region-city:before {
	@include transition(background 0.2s ease-in);
}

.academy-label {
	@include transition(background 0.2s ease-in);
}