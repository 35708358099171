.btn { font-size: 1em; display: inline-block; vertical-align: middle; text-align: center; margin-bottom: 0.5em; border: none; background: $red; color: $white; font-weight: 700; padding: 0.7em 1.75em 0.65em 1.75em; text-decoration: none; border-radius: 2em; @include box-shadow(0px 9px 18px 0px rgba($red, 0.35)); }
.btn:hover { background: $darkBlue; color: $white; @include box-shadow(0px 9px 20px 0px rgba($darkBlue, 0.6)); }

.btn-gray { background: $btnGrayBlue; @include box-shadow(0px 9px 18px 0px rgba($btnGrayBlue, 0.5)); }
.btn-gray:hover { background: $darkBlue; }

.btn-question-form.active { background: $pink; @include box-shadow(none); }

.btn-disabled { background: $pink; @include box-shadow(none); }

.btn-chevron .icon { height: 1em; width: 1em; margin-top: 0.22em;  color: $red; }
.btn-chevron-left .icon {  margin-right: 0.35em; margin-left: -0.35rem; }
.btn-chevron-right .icon {  margin-left: 0.35em; margin-right: -0.35rem; transform: rotate(180deg); }

.btn-category { @include box-shadow(none); background: $lightBlue url('../svg/src/chevron-right-red.svg') right 1em center no-repeat; background-size: 0.9em 0.9em; color: $darkBlue; padding: 0.55em 2.5em 0.45em 1.5em; }
.btn-category:hover { background: $darkBlue url('../svg/src/chevron-right-white.svg') right 1em center no-repeat; background-size: 0.9em 0.9em; }
.btn-category-active { color: $white; background: $darkBlue url('../svg/src/chevron-right-red.svg') right 1em center no-repeat; background-size: 0.9em 0.9em; }
.btn-category-simple { background-image: none; padding-right: 1.5em; }
.btn-category-simple:hover { background-image: none; padding-right: 1.5em; }