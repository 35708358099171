.header {
	position: fixed;
	z-index: 4000;
	width: 100%;
	left: 0;
	top: 0;
	transition: top 0.2s ease-in-out, height 0.2s ease-in-out;

	.inner {
		transition: height 0.2s ease-in-out;
	}

	.main-nav-link {
		transition: line-height 0.2s ease-in-out;
	}

	.header-logo {
		transition: transform 0.2s ease-in-out;
		transform-origin: left center;
	}

	&--fixed {
		position: fixed;
		top: -8rem;
		left: 0;
	}

	&--scrolled, &.header--scrolled {
		height: 80px;

		.inner {
			height: 80px;
		}

		.main-nav-link {
			line-height: 80px;
		}

		.header-logo {
			transform: scale(0.7);
		}
	}

	&--top {
		position: absolute;
		top: 0;
		left: 0;
	}
}
