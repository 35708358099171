.map-intro { position: relative; z-index: 30; }
.map-wrap { position:relative; overflow: hidden; margin-left: -2em; margin-right: -1.5em; margin-top: 0; }
.map { max-width: 100%; height: auto; width: 100%; height: 692px; margin-top: -5em; }
.map-region { color: $mapBlue; fill: $mapBlue; stroke: $white; stroke-width: 10; stroke-miterlimit: 10; }
.map-region:hover, .map-region-hovered { cursor: pointer; color: $darkBlue; fill: $darkBlue; }
.map-region-selected { color: $darkBlue; fill: $darkBlue; }
.map-region-city { color: $red; font-weight: 700; font-size: 1em; display: block; text-decoration: none; position: absolute; z-index: 10; }
.map-region-city:hover { color: $darkBlue; }
.map-region-city:hover:after { background: $darkBlue; }
.map-region-city:hover:before { background: $darkBlue; }
.map-region-city:after { content: ""; display: block; width: 14px; height: 14px; background: $red; border-radius: 50%; position: absolute; left: 50%; top: 1.75em; @include translateX(-6px); }
.map-region-city:before { content: ""; display: block; width: 28px; height: 28px; background: transparent; border: 3px solid $white; border-radius: 50%; position: absolute; }
.map-region-city-hovered { color: $darkBlue; }
.map-region-city-hovered:after { background: $darkBlue; }

#praha-mesto {
  left: 23%;
  top: 24%;
}
#praha-mesto:before { display: none; }
#brno { left: 41.5%; top: 62.5%; }
#brno:after { top: -1em; }
#brno:before {  left: 50%; top: -1em; @include translate(-13px, -7px); }
#bratislava { left: 37%; top: 83%; }
#bratislava:after { left: auto; right: -1.7em; top: 0.25em; }
#bratislava:before { right: -1.7em; top: 0.25em; @include translate(1px, -7px); }

.map-title { opacity: 0; visibility: hidden; width: 0; height: 0; z-index: 50; pointer-events: none; position: absolute; background: $white; color: $darkBlue; display: block; @include translateX(-50%); padding: 1em 1em; text-align: center; text-decoration: none; font-weight: 700; font-size: 1em; @include box-shadow(0px 5px 10px 0px rgba($black, 0.15)); }
.map-title:hover { color: $darkBlue; }
.map-title:after { content: ""; display: block; width: 0; height: 0; border-style: solid; border-width: 12px 12px 0 12px; border-color: $white transparent transparent transparent; position: absolute; left: 50%; bottom: -12px; margin-left: -12px; }
.map-title-visible { opacity: 1; width: 13.5em; height: 3.5em; visibility: visible; }

@media screen and (max-width: $lg) {
	#praha-mesto { left: 21.6%; top: 32.5%; }
	#bratislava { left: 38.6%; top: 83%; }
	.map { height: 683px; }
}

@media screen and (max-width: $md) {
	#praha-mesto { left: 21.5%; top: 24.5%; }
	#brno { left: 41.8%; top: 62.5%; }
	#bratislava { left: 38.9%; top: 83%; }
	.map { height: 572px; }
}

@media screen and (max-width: $sm) {
	.map { display: none; }
	.map-wrap { margin: 1.5em; }
  .map-region-mobile { margin-top: 1em; }
	.map-region-city, .map-title { opacity: 1; visibility: visible; display: inline-block; text-align: left; width: 49%; color: $darkBlue; height: auto; padding: 0; @include transform(none); pointer-events: auto; text-decoration: underline; @include box-shadow(none); position: relative; left: 0 !important; top: 0 !important; }
	.map-region-city:after, .map-region-city:before { display: none; }
}

@media screen and (max-width: $xs) {
	.map-region-city, .map-title { width: 100%; }
}
