.content { padding: $headerHeight 0 0 0; }
.svg-sprite { width: 0; height: 0; position: absolute; }

/* Block */

.block { padding: 2.75em 0; }
.block:last-child:not(.block-omega, .block-narrow) { padding-bottom: 3.5em; }
.block.block-narrow { padding: 1.5em 0; }

.block.block-alt { background: $lightBlue; }

.block.block-alpha { padding-top: 0; }
.block.block-omega { padding-bottom: 0; }

.block-inner { padding: 2em 0; }
.block-border { border-top: 2px solid lighten($lightBlue, 3%); }
.block-img { margin: 0 auto; display: block; width: auto; }

.block-cta { padding: 0; background: $blue; color: $btnGrayBlue; }
.block-cta .cta-head { color: $white; font-size: 1.5em; font-weight: 700; background-clip: border-box; background: none; -webkit-text-fill-color: $white; }
.block-cta .btn, .block-cta .btn-gray { @include box-shadow(0px 9px 18px 0px rgba($black, 0.2)); }
.block-cta .btn:hover, .block-cta .btn:active { background-color: lighten($red, 5%); }
.block-cta .btn-gray:hover, .block-cta .btn-gray:active { background-color: lighten($btnGrayBlue, 5%); }

.cta-cols { display: flex; flex-wrap: wrap; }
.cta-col { flex: 0 0 50%; max-width: 50%; padding: 1.5em 2em; border-left: 1px solid darken($grayBlue, 5%); }
.cta-col:last-of-type { border-right: 1px solid darken($grayBlue, 5%); }

.block-contact { padding: 0; margin-bottom: 1em; position: relative; }
.block-contact-text { padding: 2.25em 1.5em 0 0; }
.block-contact-text:last-of-type { padding-bottom: 2.25em; }
.block-contact-text .list-contact { margin-bottom: 0; margin-top: 2rem; }

.section-head { text-align: center; }
.section-head h2:not(:last-child) { margin-bottom: 0.5em; }
.section-head-l { text-align: left; }
.section-intro { max-width: 500px; margin-left: auto; margin-right: auto; text-align: center; }

.narrow-col-700 { max-width: 700px; margin-left: auto; margin-right: auto; }
.narrow-col-500 { max-width: 500px; margin-left: auto; margin-right: auto; }

.box { background: $lightBlue; padding: 1em 1.5em; margin-bottom: 3px; }
.box p { margin-bottom: 0.5rem; }
.box p:last-child, .box ul:last-child { margin-bottom: 0; }
.box-white { background: $white; }
.box-border-red { background: transparent; border: 2px solid $pink; }

.box-big { background: $lightBlue; padding: 2.5rem 3rem; }

.logo-border { border: 2px solid lighten($lightBlue, 3%); padding: 2.25em 1.5em; }
.logo-border img { max-width: 100%; display: block; margin: 0 auto; }

/* Teaser */

.teaser { padding-bottom: 4.5em; min-height: 40em; }
.teaser-img { max-width: 100%; z-index: 50; margin-top: -2.25rem; }
.teaser-buttons { margin: 1em 0 3.25em 0; }
.teaser-buttons .btn { margin-right: 0.75em; margin-bottom: 1em; }
.teaser-head { @extend .h1; font-weight: 400; }
.teaser h2:not(.h1) { margin-bottom: 0.4em; }
.teaser-content { margin-bottom: 1.75rem; }
.teaser-content .teaser-list { display: flex; flex-wrap: wrap; margin-left: -1.5rem; }
.teaser-content .teaser-list li { width: calc(50% - 1.5rem); margin-left: 1.5rem; font-size: 1rem; }

/* Product list */

.item-list-products { justify-content: center; align-items: stretch; margin-bottom: -1.5em; }
.item-product { width: 25%; text-align: center; text-decoration: none; padding: 1em 1.25em; position: relative; margin-bottom: 1em; }
.item-product:hover { text-decoration: none; @include box-shadow(0px 9px 18px 0px rgba($btnGrayBlue, 0.35)); }
.item-product:hover .item-product-head { @include scale(0.6); font-weight: 300; }
.item-product:hover .item-product-number { @include transform(scale(1.6) translate(0.3em, -0.02em)); font-weight: 400; }

.item-product-number { display: inline-block; }
.item-product-head { color: $red; font-size: 1.5em; margin-bottom: 0.4rem; white-space: no-wrap; font-weight: 400; }
.item-product-prices { min-height: 105px; display: flex; flex-direction: column; justify-content: center; }
.item-product-prices button { align-self: center; }
.item-product-price { color: $darkBlue; font-size: 0.9em; margin-bottom: 0; white-space: no-wrap; }
.item-product-price.regular { text-decoration: line-through; }
.item-product-price.sale { color: #9c1f00; font-weight: 700; }
.item-product-img { max-width: 100%; max-height: 250px; display: block; margin: 0 auto 0.8rem auto; }
.item-product-list { text-align: left; margin-top: 1.75em; margin-bottom: 0; font-size: 0.8em; list-style-type: none; }
.item-product-list li { position: relative; padding-left: 1.25em; margin-bottom: 1.25em; }
.item-product-list li:before { content: ""; display: block; width: 8px; height: 8px; border-radius: 50%; background: $darkBlue; position: absolute; left: 0; top: 0.5em; }
.item-product-list li:last-of-type { margin-bottom: 0; }
.item-product-btn { margin-bottom: 0.75rem; }
.item-product-desc { text-align: center; font-size: 0.9rem; }
.item-product-desc:not(:last-child) { margin-bottom: 0.75rem; }

.item-product-labels-wrap { height: 2.4rem; margin-top: -0.35rem; }
.item-product-labels-wrap-auto { height: auto; }
.item-product-label { display: inline-block; margin-bottom: 0.7rem; background: $red; color: $white; border-radius: 0.75rem; padding: 0.2rem 1rem 0.13rem 1rem; font-size: 0.75rem; }

.item-list-products-smaller .item-product { width: 20%; margin-bottom: 0; margin-bottom: 2.5em; }

.item-list-products-small .item-product { width: 33.33333%; }
.item-list-products-small .item-product:hover .item-product-head { @include transform(none); }
.item-list-products-small .item-product:hover .item-product-number { @include transform(none); }
.item-list-products-small .item-product-number { font-size: 1.6em; font-weight: 400; }
.item-list-products-small .item-product-head { font-size: 0.9em; font-weight: 300; }

/* News list */
.item-list-news-sidebar { padding-left: 0.25rem; }
.item-list-news { margin-left: -1.5em; display: inline-block; }
.item-news { width: 33.33333%; padding-left: 1.5em; text-decoration: none; }
.item-news-head { font-size: 1em; color: $darkBlue; text-decoration: underline; margin-bottom: 0.35em; font-weight: 700; }
.item-news:hover .item-news-head { text-decoration: none; color: $red; }
.item-news:hover .item-news-img img { @include scale(1.1); transform-origin: center center; }
.item-news-img { margin-bottom: 0.75em; height: 170px; overflow: hidden; }
.item-news-img img { min-width: 100%; min-height: 100%; max-width: none; }
.item-news-date { margin-bottom: 0; font-size: 0.9em; }

.item-list-news-vertical .item { width: 100%; margin-bottom: 1.5em; }

/* Products */

.categories { margin-bottom: 0.75em; }
.categories-big { text-align: center; }
.categories-text { display: inline-block; vertical-align: middle; color: $darkBlue; font-weight: 700; margin: 0 0.5em 1em 0; }
.categories .btn { margin: 0 0.3em 1em 0.3em; vertical-align: middle; }

/* Product detail */

.product-price { color: $darkBlue; font-size: 1.5em; font-weight: 700; margin-bottom: 0.3em; }
.product-price.regular { text-decoration: line-through; font-weight: 400; }
.product-price.sale { color: #9c1f00; }
.product-display {  }
.product-software { padding: 1.5em 0; border: 2px solid lighten($lightBlue, 3%); display: block; margin: 0 auto 1.5em auto; text-align: center; }
.product-software img { max-width: 100%; display: inline-block; }
.product .list-files { margin-bottom: 2.25em; }
.product-guide { border-top: 2px solid lighten($lightBlue, 3%); border-bottom: 2px solid lighten($lightBlue, 3%); margin-top: 1.5rem; padding: 1rem 0; }
.product-guide-link { color: $red; }
.product-guide-link:hover, .product-guide-link:active, .product-guide-link:focus { color: $red; }

/* About measurement */

.measurement { position: relative; }
.measurement-teaserimg { position: absolute; right: 0; bottom: 0; max-height: 100%; z-index: 1; width: auto; }
.measurement-text { position: relative; z-index: 20; }

/* Support */

.faq { margin-bottom: 2.5em; }
.faq-item { border: 2px solid $lightBlue; margin-bottom: 1em; }
.faq-head { color: $darkBlue; font-size: 1em; font-weight: 700; padding: 1.25em 1.35em; margin: 0; display: block; position: relative; }
.faq-head:hover { background: $lightBlue; cursor: pointer; }
.faq-head.active { background: $lightBlue; }
.faq-head:before, .faq-head:after { content: ""; display: block; width: 1em; height: 3px; background: $red; position: absolute; right: 1em; top: 50%; }
.faq-head:after { @include rotate(90deg); }
.faq-head.active:after { @include rotate(0); }
.faq-text { padding:  0 1.35em 1.25em 1.35em; background: $lightBlue; }
.faq-question { font-weight: 700; }
.faq-answer { font-weight: 400; padding-top: 1em; }
.faq-text p:last-child { margin-bottom: 0; }

/* Content navigation */

.content-nav { letter-spacing: -0.31em; *letter-spacing: normal; *word-spacing: -0.43em; padding: 0; position: relative; }
.content-nav-wrap { width: 16.66666%; display: inline-block; *display: inline; zoom: 1; vertical-align: top; letter-spacing: normal; word-spacing: normal; list-style-type: none; padding: 0 0 0 4px; background: none; margin: 0; }
.content-nav-link { @extend .btn, .btn-gray; border-radius: 0; width: 100%; height: 3.9em; text-align: center; padding: 1.35em 0.25em; line-height: 1.3; vertical-align: middle; position: relative; }
.content-nav-link-high { padding: 0.7em 1em; }
.content-nav-link:hover { background: lighten($darkBlue, 10%); @include box-shadow(0px 9px 20px 0px rgba($darkBlue, 0.6)); }
.content-nav-wrap:first-of-type .content-nav-link { border-radius: 2em 0 0 2em; }
.content-nav-wrap:last-of-type .content-nav-link { border-radius: 0 2em 2em 0; }

.content-nav-4 .content-nav-wrap { width: 25%; }

/* Help */

.academy-label { background: $darkBlue; color: $white; text-align: center; border-radius: 50%; width: 13em; height: 13em; padding: 2.5em 2.25em; display: block; margin: 0 auto 2.5em auto; font-weight: 700; position: relative; text-decoration: none; }
.academy-label:hover, .academy-label:active, .academy-label:focus { text-decoration: none; color: $white; background: lighten($darkBlue, 3%); }
.academy-label-text { position: relative; z-index: 20; }
.academy-label:after { content: ""; display: block; width: 12em; height: 12em; border: 2px solid $white; opacity: 0.25; border-radius: 50%; position: absolute; left: 0.5em; top: 0.5em; z-index: 1; }
.academy-text { text-transform: uppercase; letter-spacing: 3px; font-size: 1.2em; font-weight: 400; }

.help-item:not(:last-child) { margin-bottom: 2em; }
.help-question { background: $lightBlue url('../svg/src/icon-bubble-gradient.svg') right 1.35em top 1.5em no-repeat; background-size: 1.5em 1.5em; padding: 1.5em; border-top: 2px solid $darkBlue; }
.help-title { padding-right: 2em; }
.help-answer { border: 2px solid $lightBlue; border-bottom: none; padding: 1.5em; margin-left: 1.5em; }
.help-answer:nth-of-type(2) { border-top: none; }
.help-answer:nth-last-child(-n+2) { border-bottom: 2px solid $lightBlue; }
.help-foot { text-align: right; font-size: 0.8em; margin-bottom: 0; margin-top: 1.5em; }
.help-foot a { color: $red; font-weight: 700; }
.help-pipe { margin: 0 0.25em; }

/* Academy */

.academy-img { position: absolute; left: 60%; top: 5em; z-index: -1; }
.academy-content { position: relative; z-index: 20; }

.counter-wrap { margin-bottom: 0.75em; }
.counter { background: $darkBlue; color: $white; text-transform: uppercase; font-weight: 700; text-align: center; padding: 0.7em; min-width: 7em; border-radius: 0.25em; margin-right: 1em; margin-bottom: 1em; }
.counter-number { font-size: 3em; display: block; line-height: 1.2; }

/* Article detail */

.article-head { margin-bottom: 0; }
.article-subhead { margin-bottom: 0; font-weight: 400; }
.article-head-wrap { margin-bottom: 2.75em; }
.article-date { display: inline-block; background: $white; @include transform(translateY(-1em)); margin: 0 -0.35em 0.3em -0.35em; padding: 0 0.35em; }
.article-content { border-top: 1px solid darken($lightBlue, 5%); padding-bottom: 1em; }
.article-back-btn { border-top: 1px solid darken($lightBlue, 5%); padding-top: 2.5em; }

/* Contact */

.item-list-contact { margin-top: 3em; border-left: 1px solid $lightBlue; }
.item-contact { width: 50%; padding: 2.5em; border-right: 1px solid $lightBlue; }
.item-contact:not(:nth-last-child(-n+2)) { border-bottom: 1px solid $lightBlue; }
.item-contact-img { width: 9.5em; height: 9.5em; }
.item-contact-name { font-size: 1em; color: $darkBlue; font-weight: 700; margin-bottom: 1em; }

/* Testimonials */

.testimonials { margin: 0; }
.testimonials .item-list {  }
.testimonials .item { margin-bottom: 40px; }
.testimonials .item-inner { position: relative; padding-left: 80px; padding-bottom: 20px; border-bottom: 1px solid #9c1f00; }
.testimonials .item-inner:before { content: '"'; font-family: Georgia,sans-serif; font-size: 100px; position: absolute; left: 0; top: -30px; color: #9c1f00; }
.testimonials .item .desc { font-style: italic; }
.testimonials .item .name { font-size: 20px; line-height: 1.3em; font-weight: 600; color: #9c1f00; }

.modal-testimonial { width: 100%; max-width: 700px }
.modal-testimonial .item { margin-bottom: 0; }
.modal-testimonial .item-inner { border: none; padding-bottom: 0; }
.modal-testimonial .name { margin-bottom: 0; }

/* Funding */

.funding h2 { font-size: 30px; line-height: 1.3em; font-weight: 600; color: #003163; }
.funding .funding-inner { display: block; position: relative; }
.funding .main-img { max-width: 212px; position: absolute; right: 0; top: 0; }
.funding .pr { padding-right: 280px; }
.funding .excerpt { color: #628090; margin-bottom: 70px; max-width: 660px; }
.funding .excerpt p { font-size: 30px; font-weight: 300; }
.funding .excerpt .btn-set { display: flex; flex-wrap: wrap; }
.funding .excerpt .btn-set .btn { margin-right: 40px; margin-bottom: 20px; }

.fund-switch { margin-bottom: 40px; }
.fund-switch .head { max-width: 660px; }
.fund-switch .box { padding: 40px; border-radius: 5px; }
.fund-switch .box .inner { display: flex; }
.fund-switch .box .img { flex: 0 0 110px; max-width: 110px; }
.fund-switch .box h3 { font-weight: 600; }
.fund-switch .box h3 .alt { color: #9c1f00; }

.fund-switch .tab-nav { padding-top: 100px; margin: 0; position: relative; }
.fund-switch .tab-nav ul { margin: 0; display: flex; justify-content: space-between; position: relative; }
.fund-switch .tab-nav ul:after { content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 12px; background: #e6ebef; border-radius: 6px; }
.fund-switch .tab-nav ul li { margin: 0; padding: 0; position: relative; }
.fund-switch .tab-nav ul li:before { content: ''; position: absolute; left: 50%; margin-left: -12px; top: -6px; width: 24px; height: 24px; background: #e6ebef; border-radius: 50%; }
.fund-switch .tab-nav ul li a { font-size: 16px; font-weight: 600; display: block; padding: 30px 5px 0 5px; text-align: center; }
.fund-switch .tab-nav ul li a.selected { color: #9c1f00; text-decoration: none; }
.fund-switch .tab-nav ul li a.selected:before { content: ''; position: absolute; left: 50%; z-index: 10; margin-left: -17px; top: -11px; width: 34px; height: 34px; border: 5px solid #e6ebef; background: #9c1f00; border-radius: 50%; }
.fund-switch .tab-nav ul li a.selected:after { content: ''; position: absolute; left: 50%; margin-left: -12px; bottom: -85px; width: 0; height: 0; border-style: solid; border-width: 0 28px 28px 28px; border-color: transparent transparent #e6ebef transparent; }
.fund-switch .tab-nav ul li a .img { display: block; position: absolute; left: 50%; top: -60px; white-space: nowrap; @include translate(-50%, 0); }
.fund-switch .tab-nav ul li a .img .icon { width: 12px; height: 34px; }
.fund-switch .tab-content { padding-top: 80px; }
.fund-switch .tab-content .tab { display: none; }
.fund-switch .tab-content .tab.selected { display: block !important; position: static !important; }

.best-offer h3 { font-weight: 600; }
.best-offer .inner { display: flex; }
.best-offer .img { flex: 0 0 320px; max-width: 320px; }
.best-offer .text { display: flex; flex-grow: 1; align-items: center; }
.best-offer .text-inner { width: 100%; }
.best-offer .text .head { max-width: 320px; }
.best-offer .text .desc { max-width: 420px; }
.best-offer .text .btn-set { display: flex; flex-wrap: wrap; }
.best-offer .text .btn-set .btn { margin-right: 40px; margin-bottom: 20px; }

.cta-phone .label { font-size: 15px; font-weight: 600; display: block; color: #6c8897; }
.cta-phone .phone { font-size: 25px; font-weight: 600; display: block; color: #9c1f00; white-space: nowrap; }

.download { padding: 40px; border: 2px solid #e6ebef; border-radius: 5px; }
.download ul { margin: 0; }
.download ul li { padding-left: 35px; position: relative; margin-bottom: 10px; }
.download ul li:before { content: ''; position: absolute; left: 0; top: 2px; width: 21px; height: 22px; background: url('../img/li-dl.svg') 0 0 no-repeat; }

.financing-sk { margin-bottom: 40px; }
.financing-sk a {color: $red;}

/* Video js */

.video-js { max-width: 100%; height: auto; }

/* Gmap */

.gmap { width: 100%; height: 35em; }
.gmap img { max-width: inherit; }

.gmap-contact { height: 100%; }
.gmap-contact-higher { height: 100%; }

.popup-contact { background: $white; color: $black; width: 190px; height: 80px; padding: 0.75em; }
.popup-contact ul { margin: 0; }
.popup-contact ul li { padding: 0; background: none; }

.seminars {
  .item {
    margin-bottom: 2.5em;
	width: 100%;

    .subhead {
      span {
        font-size: 0.7em;
        font-weight: 600;
        color: #003163;
        border: 2px solid rgba(0,49,99,.3);
        border-radius: 2em;
        padding: .4em 2em;
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .item-inner {
    padding: 2em;
    background: #e6ebef;
  }
}

.item-list {
	display: flex;
	flex-wrap: wrap;
}

.bazar {
	.entry {
		margin-bottom: 40px;
	}

	.gallery {
		margin-bottom: 40px;

		.item {
			margin-bottom: 40px;

			.img {
				box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
				border-radius: 5px;
				overflow: hidden;

				img {
					display: block;
					margin: 0 auto;
				}
			}
		}
	}
}

// LP
.hero-image {
  background: #ebd2cc;
  overflow: hidden;
  &__description {
      font-size: 24px;
      max-width: 600px;
      line-height: 1.5;
      margin-bottom: 40px;
  }
}

.features-grid {
  .section-head {
    text-align: left;
    h2 {
      margin: 0;
      text-wrap: balance;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 64px;
    margin-top: 64px;
  }
  &__title {
    text-wrap: balance;
    font-size: 24px;
  }
  &__icon {
    margin-bottom: 1em;
  }
  &__description {
    font-size: 18px;
  }
}

.advantages {
  &__description {
    font-size: 18px;
  }
  &__stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    margin-top: 90px;
  }
  &__stat-box {
    border: 1px solid #C1CED3;
    padding: 30px 15px;
    text-align: center;
  }
  &__stat-number {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 0.5em;
    display: block;
  }
  &__stat-description {
    text-wrap: balance;
    margin: 0;
  }
  &__content {
    padding-right: 90px;
  }
  &__visual {
    display: flex;
    justify-content: flex-end;
  }
}

.customer-item-list {
  .customer-item {
    .customer-item-img {
      border: 1px solid #E6EBEF;
    }
  }
}

.references {
  .reference-item {
    padding: 0 46px 36px 80px;
    border-bottom: 1px solid $red;
    &__icon {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .slider-references-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: .5em;
      width: 46px;
      height: 46px;
      border: 1px solid $red;
      border-radius: 9999px;
    }
  }
}

.contact-form-wrap {
  &.contact-form-wrap__reference {
    padding: 72px;
  }
  .form-terms {
    color: $red;
  }
}

.footer-lp {
.footer-nav-wrap {
  font-size: 16px;
}
}

.contact-card {
  text-align: left;
  &__container {
    display: flex;
    gap: 1rem;
  }
  &__image-wrap {
    flex: 0 0 138px;
    max-width: 138px;
  }
  &__image {
    width: 100%;
    height: auto;
  }
  &__title {
    color: $white;
    font-size: 28px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0.75rem;
  }
  &__name {
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 1rem;
  }
  &__info {
    margin-top: 0.25rem;
  }
  &__info-item {
    gap: 0.5rem;
  }
  &__icon {
    flex-shrink: 0;
  }
  &__text {
    font-size: 1rem;
  }
}

// min width rule
@media screen and (min-width: ($sm + 1)) {
  .advantages {
    .mobile {
      display: none;
    }
  }
}

@media screen and (max-width: $xlg) {
	.teaser { padding-bottom: 4.5em; }
	.teaser-img { max-width: 100%; @include translate(0, 2em); padding-right: 3em; }
	.teaser-head { font-size: 2.65em; }
}

@media screen and (max-width: $lg) {
	.teaser-img { max-width: 100%; @include translate(0, 2em); padding-right: 7em; }

	.block.measurement {
		padding-bottom: 0;
	}

	.measurement-teaserimg {
		width: inherit;
	}
}
@media screen and (max-width: $md) {
	.content { padding-top: 100px; }
}

@media screen and (max-width: $md) {
  .hero-image {
    &__content {
      margin-bottom: 2em;
      h1 {
        font-size: 2.5em;
      }
    }
    &__description {
      font-size: 1.25em;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .features-grid {
    &__list {
      grid-template-columns: repeat(2, 1fr);
      gap: 32px;
    }
  }

  .advantages {
    &__stats {
      gap: 24px;
    }
  }
}

@media screen and (max-width: $sm) {
	.block-img { max-height: 25em; }

	.teaser { margin-top: 2em; }
	.teaser-img { @include translate(0, 2em); padding-right: 3em;  }
	.teaser-head { font-size: 3em; }

	.item-product-list { font-size: 1em; }
	.item-product { width: 33.3333%; }
	.item-product:not(:nth-last-child(-n+3)) { margin-bottom: 2.5em !important; }
	.item-list-products-smaller .item-product { width: 33.33333%; }

	.product-display { background-size: auto 15em !important; padding-bottom: 14em; }

	.measurement-teaserimg { position: relative; }

	.academy-label { margin-top: 4em; }
	.academy-img { top: -3em; }

	.box-support { margin-bottom: 2.5em; }

	.item-contact-img { margin-bottom: 1.5em; }

	.logo-leasing { margin-bottom: 1.5em; }

	.modal-testimonial { max-width: 575px }

  .advantages {
    .desktop {
      display: none;
    }
    &__img {
      max-height: 500px;
    }
  }

  .contact-card {
    margin-bottom: 1.5em;
    &__image-wrap {
      flex: 0 0 72px;
      max-width: 72px;
    }
    &__title {
      font-size: 1.5em;
    }
    &__name {
      font-size: 1.25em;
    }
    &__text {
      font-size: 1em;
    }
  }
}

@media screen and (max-width: $xs) {
	.block-img { max-height: 20em; }

	.cta-col { flex: 0 0 100%; max-width: 100%; border-bottom: 1px solid darken($grayBlue, 5%); border-left: none; border-right: none; }
	.cta-col:last-of-type { border-right: none; border-bottom: none; }

	.teaser { margin-top: 3em; }
	.teaser-img { display: none; }

	.item-product { width: 100%; }
	.item-product:not(:last-child) { margin-bottom: 3.5em !important; }
	.item-list-products-smaller .item-product { width: 100%; }

	.item-news { width: 100%; }

	.gmap-hp-references { display: none; }

	.categories .btn { margin: 0 0 0.75em 0; display: block; }

	.content-nav-wrap { width: 100% !important; display: block; }
	.content-nav-link { border-radius: 2em !important; }

	.img-xs-small { max-height: 30em; }

	.academy-img { display: none; }
	.counter { padding: 0.25em; min-width: 5em; margin-right: 0.75em; margin-bottom: 0.75em; }
	.counter-number { font-size: 2em; display: block; line-height: 1.2; }

	.item-contact { width: 100%; }
	.item-contact:not(:last-child) { border-bottom: 1px solid $lightBlue; }

	.modal-testimonial { max-width: 400px }

	.best-offer .img { flex: 0 0 180px; max-width: 180px; padding-right: 20px; }

	.funding .main-img { display: none; }
	.funding .pr { padding-right: 0; }

	.gmap-contact { height: 18rem; }
	.gmap-contact-higher { height: 18rem; }
}

@media screen and (max-width: $xxs) {
	.teaser-head { font-size: 2.5em; }
	.counter { font-size: 0.8em; }

	.modal-testimonial { max-width: 280px }
	.modal-testimonial .item-inner { padding-left: 0; }
	.modal-testimonial .item-inner:before { content: none; }

	.fund-switch .box { padding: 30px; }
	.fund-switch .box .img { flex: 0 0 60px; max-width: 60px; padding-right: 20px; }

	.best-offer .inner { display: block; }
	.best-offer .img { margin-bottom: 20px; }

	.fund-switch .tab-nav ul li a { font-size: 14px; }
	.fund-switch .tab-nav ul li a .img { top: -50px; }
	.fund-switch .tab-nav ul li a .img .icon { width: 8px; height: 22px; }

  .seminars {
    .item-inner {
      padding: 1.5em 1em;
    }
  }

  .features-grid {
    &__list {
      grid-template-columns: 1fr;
    }
  }

  .advantages {
    &__stats {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }

  .slider-references-buttons {
    margin-top: 1.5em;
  }
}
