.entry table { width: 100%; margin: 0 0 30px 0; }
.entry table th { font-weight: normal; border-bottom: 1px solid $gray; padding: 14px 30px; background: $white; color: $black; }
.entry table td { border-bottom: 1px solid $gray; padding: 14px 30px; }

.table-specification { font-size: 0.8em; border: 1px solid darken($lightBlue, 7%); }
.table-specification th, .table-specification td { padding: 0.75em 1.5em; font-weight: 400; border-bottom: 1px solid darken($lightBlue, 7%); }
.table-specification th { text-align: left; background: $lightBlue; border-right: 1px solid $red; color: $darkBlue; width: 25%; }

@media screen and (max-width: $sm) {
	.table-specification { font-size: 1em; }
}

@media screen and (max-width: $xs) {
	.table-specification tr, th, td { display: block; width: 100%; }
	.table-specification th { background: $lightBlue; border-bottom: 1px solid $red; border-right: none; width: 100%; }
}