.timeline { letter-spacing: -0.31em; *letter-spacing: normal; *word-spacing: -0.43em; position: relative; padding: 9em 0 7.5em 0; margin: -3em 0 -1.5em 0; }
.timeline:after { content: ""; display: block; width: 2px; height: 100%; background: $paleRed; position: absolute; left: 50%; top: 0; @include translateX(-1px); }
.timeline-block { padding: 0; position: relative; }
.timeline-block:before, .timeline-block:after { content: ""; display: block; position: absolute; left: 50%; z-index: 15; }
.timeline-block:before { width: 8px; height: 8px; background: $red; top: -3em; border-radius: 50%; @include translateX(-4px); }
.timeline-block:after { width: 26px; height: 16px; background: url('../svg/src/chevron-down-red.svg') center center no-repeat; background-size: 100% auto; bottom: -1.5em; @include translate(-13px, 3px); }

.timeline-item { position: relative;  }
.timeline-item:not(:last-child) { margin-bottom: 4.5em; }

.timeline-item:after { opacity: 0; visibility: hidden; content: ""; display: block; width: 8px; height: 8px; background: $red; position: absolute; top: 0.75em; border-radius: 50%; z-index: 15; }
.timeline-row-left .timeline-item:after { @include transform(scale(0) translateX(-1.5em)); }
.timeline-row-right .timeline-item:after { @include transform(scale(0) translateX(1.5em)); }

.timeline-item-inner { opacity: 0; visibility: hidden; }
.timeline-item-inner ul li { margin-bottom: 0.25rem; }
.timeline-row-left .timeline-item-inner {  @include transform(scale(0) translate(-5em, 3em)); }
.timeline-row-right .timeline-item-inner { @include transform(scale(0) translate(5em, 3em)); }

.timeline-item.timeline-item-visible .timeline-item-inner { opacity: 1; visibility: visible; @include transform(scale(1) translate(0, 0)); }
.timeline-item.timeline-item-visible:after { opacity: 1; visibility: visible; }
.timeline-row-left .timeline-item.timeline-item-visible:after { @include transform(scale(1) translateX(4px)); }
.timeline-row-right .timeline-item.timeline-item-visible:after { @include transform(scale(1) translateX(-4px)); }

.timeline-row { width: 50%; position: relative; display: inline-block; *display: inline; zoom: 1; vertical-align: top; letter-spacing: normal; word-spacing: normal; }
.timeline-row-left .timeline-item { padding-right: 4.5em; }
.timeline-row-left .timeline-item:after { right: 0; }
.timeline-row-left .timeline-item:first-child { margin-top: 7em; }
.timeline-row-right .timeline-item { padding-left: 4.5em; }
.timeline-row-right .timeline-item:after { left: 0; }

.timeline-item .list-bullets li { margin-bottom: 0.35em; }

@media screen and (max-width: $xs) {
	.timeline { padding: 3em 0; margin: 0; }
	.timeline-row { width: 100%; }
	.timeline:after, .timeline-block:before, .timeline-block:after { display: none; }
	.timeline-row-left .timeline-item:after { @include transform(scale(0) translateX(1.5em)); }
	.timeline-row-left .timeline-item-inner { @include transform(scale(0) translate(5em, 3em)); }
	.timeline-row-left .timeline-item.timeline-item-visible:after { @include transform(scale(1) translateX(10px)); }
	.timeline-row-right .timeline-item.timeline-item-visible:after { @include transform(scale(1) translateX(10px)); }
	.timeline-item { padding-left: 2.5em !important; }
	.timeline-row-left .timeline-item:after { left: 0; }
	.timeline-row-left .timeline-item:first-child { margin-top: 0; }
}