.footer { background: $red; color: $white; text-align: center; font-weight: 400; }
.footer a { color: $white; }
.footer a:hover { color: rgba($white, 0.5); }

.footer-block { padding: 2em 0; }
.footer-block p { margin-bottom: 0; }
.footer-block:not(:last-of-type) { border-bottom: 1px solid $paleRed; }

.footer-contact-list { font-size: 1.2em; margin: 0; padding: 0; display: flex; flex-wrap: wrap; justify-content: flex-end; margin-left: -1.5rem; }
.footer-contact-list li { position: relative; padding-left: 1.5rem; margin: 0; }
.footer-contact-list li .icon { width: 1em; height: 1em; margin-right: 0.3em; @include translateY(0.25em); }

.footer-logo { display: inline-block; }

.footer-nav { float: right; margin: 0.55em 0 0 0; }
.footer-nav .footer-nav-wrap { display: inline-block; padding: 0 0.5em; }
.footer-nav .footer-nav-link { padding: 0; font-size: 0.8em; text-transform: uppercase; }

.footer-copy { color: $paleRed; font-size: 0.8em; }
.footer-copy a { color: $paleRed; }

.porta { width: 69px; height: 15px; display: block; float: right; color: $gray; fill: $gray; }
.porta:hover { color: $white; fill: $white; }

@media screen and (max-width: $xxs) {
	.footer-contact-list li { width: 100%; margin-bottom: 0.5em; }
	.footer-contact-list li:after { display: none; }
}
