a { color: $baseFontColor; text-decoration: underline; }
a:hover, a:active { text-decoration: none; color: $baseFontColor; }

/* Heading */

h1, .h1 { font-weight: 400; color: $headingColor; @include textgradient(); font-weight: 700; display: inline-block; font-size: 3em; line-height: 1.2; margin: 0 0 0.6em 0; }
h2, .h2 { font-weight: 400; color: $headingColor; @include textgradient(); display: inline-block; font-size: 2.4em; line-height: 1.2; margin: 0 0 0.6em 0; }
h3, .h3 { font-weight: 400; color: $headingColor; font-size: 1.5em; line-height: 1.3; margin: 0 0 0.75em 0; }
h4, .h4 { font-weight: 400; color: $headingColor; font-size: 1em; line-height: 1.5; margin: 0 0 1em 0; }
h5, .h5 { font-weight: 400; color: $headingColor; font-size: 1em; line-height: 1.5; margin: 0 0 1em 0; }
h6, .h6 { font-weight: 400; color: $headingColor; font-size: 1em; line-height: 1.5; margin: 0 0 1em 0; }

.h2-smaller { font-size: 1.8em; }
.h1-mono, .h2-mono { background-clip: border-box; background: none; -webkit-text-fill-color: $headingColor; color: $headingColor; }
h1.color-red, h2.color-red { -webkit-text-fill-color: $red; color: $red; }

/* Paragraphs */

p { margin: 0 0 1em 0; }
address { font-style: normal; }
cite { font-style: normal; }

/* Lists */

ul, ol { list-style: none;  margin: 0 0 1em 0; }
ul li { margin: 0 0 0.25em 0; }
ul.unstyled li { padding: 0; background: none; }

ol { counter-reset: counter; margin-left: 0; *margin-left: 54px; margin-bottom: 1.75em; }
ol li { margin: 0 0 0.75em 0; padding: 0 0 0 2em; *list-style-type: decimal; counter-increment: counter; position: relative; }
ol li:before { color: $red; content: counter(counter)'. '; position: absolute; left: 0; top: 0; font-weight: 700; }

dl { margin: 0 0 1em 0; }
dt { font-weight: bold; }

.perex { font-size: 1.5em; }

.text-small { font-size: 0.8em; }
.text-smaller { font-size: 0.9em; }
.text-bigger { font-size: 1.33em; }
.text-big { font-size: 1.5em; line-height: 1.3; }

.text-light { font-weight: 300; }
.text-bold { font-weight: 700; }
.text-xbold { font-weight: 800; }
.text-regular { font-weight: 400; }
strong { font-weight: 400; }

.text-uppercase { text-transform: uppercase; }
.text-nowrap { white-space: nowrap; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.color-red { color: $red !important; }
.color-white { color: $white !important; }
.color-blue { color: $darkBlue !important; }
.color-gray { color: $grayBlue !important; }

.link-uppercase { color: $red; text-transform: uppercase; margin-right: 1em; font-size: 0.8em; }

.f-l { float: left; }
.f-r { float: right; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid $baseFontColor; margin: 1em 0; padding: 0; }

sup,sub { height: 0; line-height: 1; vertical-align: baseline; _vertical-align: bottom; position: relative; }
sup { bottom: 1ex; }
sub { top: .5ex; }

.entry { }
.entry h1, .entry h2 { background-clip: border-box; background: none; -webkit-text-fill-color: $headingColor; color: $headingColor; }
.entry h2 { margin-top: 0.3em; }
.entry h3 { margin-top: 0.5em; }
.entry h2:first-child { margin-top: 0; }
.entry h3:first-child { margin-top: 0; }
.entry img { margin: 0.75em 0 2em 0; }
.entry ul { margin-bottom: 1.75em; }
.entry ul li { position: relative; padding-left: 2em; margin-bottom: 0.75em; }
.entry ul li:before { content: ""; position: absolute; left: 0.25rem; top: 0.5em; width: 8px; height: 8px; display: block; background: $darkBlue; border-radius: 50%; }

.list-checked { list-style-type: none; margin-bottom: 1.5em; }
.list-checked li { padding-left: 2em; position: relative; margin-bottom: 0.5em; }
.list-checked li:before { content: ""; display: block; width: 0.9em; height: 0.9em; background: url('../svg/src/check-red.svg') center center no-repeat; background-size: 0.9em 0.9em; position: absolute; left: 0; top: 0.25em; }

.list-files { list-style-type: none; margin-bottom: 1em; }
.list-file { padding-left: 2em; position: relative; margin-bottom: 1.1em; padding-top: 0.15em; min-height: 1.75em; }
.list-file:last-child { margin-bottom: 0.75em; }
.list-file a { color: $red; }
.list-file:before { content: ""; display: block; width: 1.75em; height: 1.75em; background: url('../svg/src/files/file.svg') center center no-repeat; background-size: 1.75em 1.75em; position: absolute; left: -0.35em; top: 0; }
.list-file-pdf:before { background-image: url('../svg/src/files/pdf.svg'); }
.list-file-doc:before, .list-file-docx:before { background-image: url('../svg/src/files/word.svg'); }
.list-file-jpg:before, .list-file-png:before { background-image: url('../svg/src/files/image.svg'); }
.list-file-xls:before, .list-file-xlsx:before { background-image: url('../svg/src/files/excel.svg'); }
.list-file-ppt:before, .list-file-pptx:before { background-image: url('../svg/src/files/powerpoint.svg'); }
.list-file-avi:before, .list-file-mp4:before { background-image: url('../svg/src/files/video.svg'); }
.list-file-mp3:before { background-image: url('../svg/src/files/music.svg'); }
.list-file-txt:before { background-image: url('../svg/src/files/text.svg'); }

.list-questions { list-style-type: none; }
.list-questions li { padding-left: 2em; position: relative; margin-bottom: 1em; color: $darkBlue; }
.list-questions li a { color: $darkBlue; }
.list-questions li:before { content: ""; display: block; width: 1.5em; height: 1.5em; background: url('../svg/src/icon-bubble-gradient.svg') center center no-repeat; background-size: 1.4em 1.4em; position: absolute; left: 0; top: 0; }

.list-contact { list-style-type: none; }
.list-contact li { color: $red; font-weight: 400; border-bottom: 1px solid darken($lightBlue, 5%); padding-bottom: 0.75em; margin-bottom: 0.75em; padding-left: 2em; position: relative; }
.list-contact li a { color: $red; }
.list-contact .icon { fill: $pink; position: absolute; left: 0.25em; top: 0.25em; width: 1em; height: 1em; }
.list-contact .icon-web { width: 1.25em; height: 1.25em; left: 0.15em; }

.list-bullets { list-style-type: none; }
.list-bullets li { position: relative; padding-left: 1.25em; margin-bottom: 1em; }
.list-bullets li:before { content: ""; display: block; width: 8px; height: 8px; border-radius: 50%; background: $darkBlue; position: absolute; left: 0; top: 0.5em; }
.list-bullets li:last-of-type { margin-bottom: 0; }
