.ref-head { font-size: 1.8em; color: $darkBlue; font-weight: 400; background-clip: border-box; background: none; -webkit-text-fill-color: $darkBlue; margin-top: 1.5em; }

.ref-legends { text-align: right; margin-top: 3.15em; }
.ref-legend { font-size: 0.9em; display: inline-block; position: relative; line-height: 1.5; padding-left: 2.5em; text-decoration: none; }
.ref-legend:before { content: ""; display: block; width: 6px; height: 1.5em; position: absolute; left: 1.5em; top: 0; }
.ref-legend-red { color: $red; }
.ref-legend-red:before { background: $red; }
.ref-legend-green { color: $green; }
.ref-legend-green:before { background: $green; }
.ref-legend-gray { color: $btnGrayBlue; }
.ref-legend-gray:before { background: $btnGrayBlue; }
.ref-legend-blue { color: $darkBlue; }
.ref-legend-blue:before { background: $darkBlue; }

.ref-item-list { margin-top: 0.25em; }
.ref-item { border: 2px solid darken($lightBlue, 10%); padding: 2.25em 2.25em; position: relative; font-size: 0.9em; font-weight: 400; margin-bottom: 1.5em; }
.ref-item-red { border-left: 6px solid $red; }
.ref-item-green { border-left: 6px solid $green; }
.ref-item-gray { border-left: 6px solid $btnGrayBlue; }
.ref-item-blue { border-left: 6px solid $darkBlue; }
.ref-item p { margin-bottom: 0; }
.ref-item p:last-child, .ref-item ul:last-child { margin-bottom: 0; }

.ref-name { font-size: 1.11em; color: $darkBlue; font-weight: 700; margin-bottom: 0.25em; }
.ref-item .list-contact { margin-right: 2em; margin-top: 1rem; }

.ref-product-list li { display: inline-block; width: 49%; color: $darkBlue; margin-bottom: 0.6em; }
.ref-product-list li a { color: $darkBlue; }
.ref-img { background: $lightGrayBlue; width: 33.33333%; height: 100%; position: absolute; right: 0; top: 0; padding: 2.5em; text-align: center; display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-wrap: wrap; justify-content: center; flex-direction: column; }
.ref-img img { display: block; margin: 0 auto; max-width: 100%; max-height: 100%; }

@media screen and (max-width: $sm) {
	.ref-item { padding-bottom: 20em; }
	.ref-img { width: 100%; right: 0; bottom: 0; top: auto; height: 18em; }
}

@media screen and (max-width: $sm) {
	.ref-product-list { margin-top: 2em; }
	.ref-legend { display: block; text-align: left; margin-bottom: 0.5em; padding-left: 1.5em; }
	.ref-legend:before { left: 0; }
}