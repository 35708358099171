/* Pager */
.pager-wrap {
	margin-top: 3.75rem;
	padding-top: 2.2rem;
	border-top: 1px solid $lightBlue;
}

.pager {
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    &__item {
        display: inline-block;
        padding: 0;
        background: none;
        margin: 0 0.25rem;

        &--first {
            .pager {
                &__dots {
                    margin-left: 0.2rem;
                }
            }
        }

        &--last {
            margin-left: 0.1rem;
            .pager {
                &__dots {
                    margin-right: 0.25rem;
                }
            }
        }
    }

    &__link {
        display: inline-block;
        width: 2.3rem;
        height: 2.3rem;
        line-height: 2.4rem;
        border-radius: 50%;
		transition: background-color 0.1s ease-out, color 0.1s ease-out;
		color: $white;
		color: $darkBlue;
        text-align: center;
        text-decoration: none;
        transition: all 0.2 ease-out;
        font-size: 1em;
		font-weight: 700;
        font-weight: 700;

        &:hover,
        &:active,
        &:focus {
            background: $darkBlue;
            color: $white;
        }

        &.pager__link--active {
            background: $btnGrayBlue;
            color: $white;
			cursor: default;
        }
    }

    &__prev {
        margin-right: 1rem;
        margin-top: 0.2rem;
        position: absolute;
        left: 0;
		text-align: center;
    }

    &__next {
        margin-left: 1rem;
        margin-top: 0.2rem;
        position: absolute;
        right: 0;
		text-align: center;
    }

    &__dots {
        font-size: 1rem;
		font-weight: 700;
		padding: 0 0.15rem;
        display: inline-block;
    }
}

@media screen and (max-width: $sm) {
	.pager-wrap {
		margin-bottom: 3rem;
	}
}

@media screen and (max-width: $xs) {
    .pager {
        flex-wrap: wrap;
        justify-content: center;

        &__list {
            width: 100%;
            order: 3;
            margin-top: 1.2rem;
        }

        .pager__prev {
            order: 1;
            margin-right: 0.6rem;
            position: relative;
        }
        .pager__next {
            order: 2;
            margin-left: 0.6rem;
            position: relative;
        }
    }
}