.image-guide-item-list { margin: 0 0 -1.5rem -1.5rem; }
.image-guide-item { width: 33.33333%; padding-left: 1.5rem; margin-bottom: 1.5rem; }
.image-guide-item-inner { text-align: center; display: block; text-decoration: none; }
.image-guide-item-img { width: 100%; height: 240px; overflow: hidden; background: darken($lightBlue, 10%); margin-bottom: 1.25rem; }
.image-guide-item-img img { min-width: 100%; min-height: 100%; max-width: none; transition: transform 0.2s ease-out; }
.image-guide-item-title { @extend .h3; color: $darkBlue; margin-bottom: 0; font-weight: 400; font-size: 1.3rem; }
//.image-guide-item-content { border: 2px solid darken($lightBlue, 10%); padding: 1.25rem; margin-top: -2px; }
//.image-guide-item-content { border: 2px solid $lightBlue; background: $lightBlue; padding: 1.25rem; margin-top: -2px; }

a.image-guide-item-inner {
	.image-guide-item-title { text-decoration: underline; }

	&:hover, &:active, &:focus {
		.image-guide-item-title { text-decoration: none; }
		.image-guide-item-img img { @include scale(1.05); }
	}
}

@media screen and (max-width: $md) {
	.image-guide-item { width: 50%; }
}

@media screen and (max-width: $xs) {
	.image-guide-item { width: 100%; }
}