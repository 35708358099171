html { height: 100%; font-size: $baseFontSize; overflow-x: hidden; position: relative; }

body {
	font-family: $baseFont;
	font-size: $baseFontSize;
	line-height: $baseLineHeight;
	color: $baseFontColor;
	background: $white;
	font-weight: $baseFontWeight;
	margin: 0;
	padding: 0;
	min-height: 100%;
	overflow-x: hidden;
	position: relative;

	&.sticky-content-wrapper {
		overflow-x: visible;
	}
}

/* Box sizing */

*, *:after, *:before { box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }

/* Forms */

form { display: block; }
fieldset { border: none; }
label { font-weight: bold; }
input, select, button { vertical-align: top; }
a, a:hover, a:active, a:focus { outline: 0; }
input[readonly] { opacity:.8; cursor:not-allowed; }

/* Various */

img { max-width: 100%; height: auto; }

.sh-content { display: none; }


/* Alignment */

.v-top { vertical-align: top; }
.v-middle { vertical-align: middle; }
.v-bottom { vertical-align: bottom; }

/* Vertical align */

.va-wrap { display: table; width: 100%; height: 100%; }
.va-m { display: table-cell; vertical-align: middle; height: 100%; }
.va-t { display: table-cell; vertical-align: top; height: 100%; }
.va-b { display: table-cell; vertical-align: bottom; height: 100%; }

.col-set {  display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-wrap: wrap; width: 100%; }

.col2-set .col { width: 49%; margin-bottom: 1%; }
.col3-set .col { width: 32.33333%; margin-bottom: 1%; }
.col4-set .col { width: 24%; padding: 0 0 0 1%; }
.col5-set .col { width: 19%; padding: 0 0 0 1%; }

.ib { font-family: Arial,sans-serif; letter-spacing: -0.31em; *letter-spacing: normal; *word-spacing: -0.43em; }
.ib > * { font-family: Arial,sans-serif; display: inline-block; *display: inline; zoom: 1; vertical-align: top; letter-spacing: normal; word-spacing: normal; }

.last {
  margin-bottom: 0 !important;
}

/* Layout */

@for $i from 0 through 5 {
  .m-t-#{$i} { margin-top: #{$i}em; }
  .m-b-#{$i} { margin-bottom: #{$i}em; }
  .m-l-#{$i} { margin-left: #{$i}em; }
  .m-r-#{$i} { margin-right: #{$i}em; }
  .p-t-#{$i} { padding-top: #{$i}em; }
  .p-b-#{$i} { padding-bottom: #{$i}em; }
  .p-l-#{$i} { padding-left: #{$i}em; }
  .p-r-#{$i} { padding-right: #{$i}em; }
}

.m-t--0-5 { margin-top: -0.5rem !important; }
.m-t--1 { margin-top: -1rem !important; }
.m-t--1-5 { margin-top: -1.5rem !important; }
.m-t--2 { margin-top: -2rem !important; }

.m-b-0-25 { margin-bottom: 0.25em; }
.m-b-0-5 { margin-bottom: 0.5em; }
.m-b-1-5 { margin-bottom: 1.5em; }
.m-b-2-5 { margin-bottom: 2.5em; }

.m-t-0-25 { margin-top: 0.25em; }
.m-t-0-5 { margin-top: 0.5em; }
.m-t-1-5 { margin-top: 1.5em; }

.m-l-a { margin-left: auto; }
.m-r-a { margin-right: auto; }

.no-margin { margin: 0 !important; }
.no-padding { padding: 0; }

.img-center { display: block; margin: 0 auto; }

@media screen and (max-width: $lg) {
	body { font-size: $baseFontSizeMd; }
}

@media screen and (max-width: $md) {
	body { font-size: $baseFontSizeSm; }
}

@media screen and (max-width: $sm) {
	body {
		font-size: $baseFontSizeXs;

		&.sticky-content-wrapper {
			overflow-x: hidden;
		}
	}
}

@media screen and (max-width: 767px) {
	.p-l-sm-0 { padding-left: 0; }
	.m-t-1-5 { margin-top: 1.5rem !important; }
	.m-t-2 { margin-top: 2rem !important; }
	.m-t-2-5 { margin-top: 2.5rem !important; }
}