.header {
	padding: 0;
	background: $white;
	color: $red;

	.inner {
		height: $headerHeight;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.header-logo { display: block; position: relative; text-decoration: none; color: $red; text-align: center; z-index: 100; transform: scale(0.92); padding-top: 0.2rem; }
.header-logo:hover, .header-logo:active, .header-logo:focus { color: $red; }

.header-claim-wrap { display: block; overflow: hidden; height: 1.25em; font-size: 1.04em; @include translateY(-4px); font-weight: 300; text-align: left; }
.header-claim { opacity: 0; display: inline-block; line-height: 1; @include translateY(-1em); }
.header-claim-visible { opacity: 1; @include translateY(0); }

.header-r {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.header-pink {
  background: $pink;
}

/* Main navigation */

.main-nav { margin: 0; }
.main-nav ul { margin: 0 -1.5em; display: flex; align-items: center; padding-right: 0.5rem; }
.main-nav-wrap { padding: 0; margin: 0; background: none; display: inline-block; }
.main-nav-wrap-btn { margin-left: 1rem; }
.main-nav-wrap-btn .btn { padding: 0.75em 1.5em 0.65em 1.5em; }
.main-nav-link { color: $red; font-size: 0.8em; text-transform: uppercase; display: block; line-height: 5.55em; white-space: no-wrap; padding: 0 1.5em; text-decoration: underline; }
.main-nav-link:hover, .main-nav-link:active, .main-nav-link:focus { color: $darkBlue; text-decoration: none; }
.main-nav-link-active { color: $red; }
.main-nav-flag { width: 1.1rem; height: auto; padding-top: 0.7em; padding-bottom: 0.7em; }

.login {
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;

	ul {
		margin: 0;

		li {
			margin: 0;
			padding: 0 0.5em;
			display: inline-block;

			a:not(.btn) {
				color: $white;
				font-size: 0.9em;
				display: block;
				line-height: 5.55em;
				white-space: nowrap;
			}
			a.btn {
				vertical-align: middle;
			}
		}
	}
}

.menu-trigger {
	border: none;
	width: 2.5rem;
	height: 2.5rem;
	display: none;
	text-align: center;
	padding: .5em;
	padding-right: 0;
	z-index: 1100;
}

.dropdown {
	position: relative;
	display: block;
	overflow: hidden;

	&-trigger {
		position: relative;
		padding-right: 1.5rem;

		.icon {
			content: "";
			width: 0.7rem;
			height: 0.7rem;
			position: absolute;
			right: 0.4rem;
			top: 50%;
			margin-top: -0.35rem;
			stroke: currentColor;
			fill: none;
			transition: transform 0.07s ease-out;
		}
	}

	&:hover {
		overflow: visible;

		.dropdown-menu, ul.dropdown-menu {
			opacity: 1;
		}

		.dropdown-trigger {
			text-decoration: none;
			.icon {
				transform: rotate(180deg);
			}
		}
	}

	&-menu, ul.dropdown-menu {
		position: absolute;
		left: 0.6rem;
		top: calc(100% - 1.4rem);
		display: block;
		margin: 0;
		padding: 0.4rem 0.6rem 0.25rem 0.6rem;
		background: $white;
		min-width: 13rem;
		z-index: 5000;

		opacity: 0;
		transition: opacity 0.2s ease-out;
	}

	&-link {
		display: block;
		font-size: 0.9em;
		//text-transform: uppercase;
		text-decoration: none;
		color: $red;
	}
}

@media screen and (max-width: 1460px) {
	.main-nav ul { margin: 0 -1em; }
	.main-nav-link { padding: 0 1em; }

	.dropdown {
		&-trigger {
			padding-right: 1.5rem;
		}

		&-menu, ul.dropdown-menu {
			left: 0;
		}
	}
}

@media screen and (max-width: $md) {
	.header { height: 100px; width: 100vw; }

	.icon-logo { max-width: 180px; }
}

@media screen and (min-width: $md) {
	.dropdown-desktop-hidden {
		display: none;
	}
}

@media screen and (max-width: $md) {
	.menu-trigger { display: block; }
	.main-nav { background: $red; z-index: 1000; width: 100%; left: 100%; top: 0; padding: 1em 1em 2em 5em; position: absolute;  }
	.main-nav ul { margin: 0; width: 100%; display: block; }
	.main-nav-wrap { display: block; width: 100%; }
	.main-nav-link { line-height: 2.75 !important; font-size: 1.1em; text-decoration: none; color: $white; border-bottom: 1px solid $paleRed; @include transition(all 0.25s ease-in); }
	.main-nav-link:hover, .main-nav-link:active, .main-nav-link:focus { color: $white; border-bottom: 1px solid $white; }
	.main-nav-wrap-btn .btn { background: $white; color: $red; margin-top: 0.7rem; margin-left: -0.7rem; }

	.menu-open .menu-trigger { position: absolute; left: 1em; top: 1em; }
	.menu-open .main-nav { left: 0; @include box-shadow(0px 9px 18px 0px rgba($black, 0.4)); }
	.menu-open .icon-menu { fill: $white; color: $white; }

	.dropdown {
		overflow: visible;

		.dropdown-trigger-click {
			display: block;
			width: 4rem;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;

			&.active {
				.icon {
					transform: rotate(180deg);
				}
			}
		}

		.dropdown-trigger {
			padding-right: 5rem;
		}

		&-menu, ul.dropdown-menu {
			position: relative;
			padding: 0.5rem 1.5rem;
			opacity: 1;
			display: none;
			background: none;
			border-bottom: 1px solid $paleRed;
		}

		&-link {
			color: $white;
			font-size: 1.1em;
			text-transform: uppercase;
			line-height: 2.25;

			&:hover, &:active {
				color: $white;
			}
		}
	}
}

@media screen and (max-width: $xxs) {
	.header { height: 70px; }
	.header-logo { width: 160px; font-size: 0.7em; }
	.header-claim-wrap { margin-left: 14px; }
}
