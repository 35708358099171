.interpretation {
	position: relative;
	display: inline-block;

	&-marker {
		position: absolute;
		width: 1.5rem;
		height: 1.5rem;
		background: $red;	
		transform: translate(-50%, -50%);
		border-radius: 50%;
		transition: all 0.1s ease-out;

		&:before, &:after {
			content: "";
			display: block;
			border-radius: 50%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: all 0.1s ease-out;
		}
		&:before {
			width: 2.6rem;
			height: 2.6rem;
			background: $red;
			opacity: 0.3;
			animation-name: pulse;
				animation-duration: 3s;
				animation-timing-function: ease-out;
				animation-direction: normal;
				animation-iteration-count: infinite;
		}
		&:after {
			width: 1.5rem;
			height: 1.5rem;
			background: url('../svg/src/plus-white.svg') center center no-repeat;
			background-size: 1rem 1rem;
		}

		&:hover, &:active {
			transform: translate(-50%, -50%) scale(1.2) rotate(90deg);
		}
	}
}

@keyframes pulse {
	0% {
		transform: translate(-50%, -50%) scale(0);
		opacity: 0.3;
	}

	50% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 0.3;
	}

	89% {
		transform: translate(-50%, -50%) scale(1.1);
		opacity: 0.05;
	}

	90% {
		transform: translate(-50%, -50%) scale(0);
		opacity: 0;
	}

	100% {
		transform: translate(-50%, -50%) scale(0);
		opacity: 0.3;
	}
}

.interpretation-block {
	&-head {
		margin-bottom: 0;
	}

	&-content {
		padding-top: 1.5rem;
	}

	&-img {
		padding-right: 1rem;
	}

	&-text {
		padding-left: 1rem;
	}
}

@media screen and (max-width: 767px) {
	.interpretation-block {
		padding: 1.1rem 0 0.9rem 0;

		&-head {
			background-clip: border-box;
			background: none;
			-webkit-text-fill-color: $headingColor;
			color: $headingColor;
			display: block;

			font-size: 1.6em;
			padding-right: 3rem;
			cursor: pointer;
			position: relative;

			&:before, &:after {
				content: "";
				display: block;
				width: 1rem;
				height: 2px;
				background: $red;
				position: absolute;
				right: 1em;
				top: 50%;
				transition: transform 0.1s ease-out;
			}
			&:after {
				transform: rotate(90deg);
			}

			&.active {
				&:after {
					transform: rotate(0);
				}
			}
		}

		&-content {
			.entry {
				p, ul, ol, li {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		&-content {
			display: none;
		}

		&-img {
			padding-right: 0;

			img {
				margin-bottom: 1.5rem;
			}
		}

		&-text {
			padding-left: 0;
			padding-bottom: 0.4rem;
		}
	}
}

.to-top {
	display: block;
	width: 2.8rem;
	height: 2.8rem;
	position: sticky;
	left: 100%;
	bottom: 3rem;
	margin-right: -5rem;
	z-index: 3000;

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background: $gray;
		border-radius: 50%;
		position: absolute;
		left: 0;
		top: 0;
		transition: all 0.2s ease-out;
	}

	&--icon {
		transform: translate(-50%, calc(-50% - 0.1rem)) rotate(90deg);
		fill: $red;
		width: 1.2rem;
		height: 1.2rem;
		position: absolute;
		left: 50%;
		top: 50%;
		transition: all 0.2s ease-out;
	}

	&:hover {
		&:before, .to-top--icon {
			margin-top: -0.4rem;
			/*animation-name: toTop;
			animation-duration: 0.4s;
			animation-timing-function: ease-out;*/
		}
	}
}

/*@keyframes toTop {
	0% { margin-top: 0; }
	50% { margin-top: -0.5rem; }
	100% { margin-top: 0; }
}*/

@media screen and (min-width: 767px) {
	.interpretation-block {
		&-content {
			display: block !important;
		}
	}
}

@media screen and (max-width: $lg) {
	.to-top {
		margin-right: -4rem;
	}
	.to-top-container {
		padding-right: 4.5rem;
	}
}

@media screen and (max-width: $sm) {
	.to-top {
		margin-right: 0.75rem;
		margin-top: 1rem;
		bottom: 2.5rem;
		//transform: scale(0.9);
	}
	.to-top-container {
		padding-right: 0;
	}

	.to-top {
		position: fixed;
		left: auto;
		right: 0;
		bottom: 3rem;
	}
}