/* Articles (blog) */
.article-item {
	margin-bottom: 1em;

	&__inner {
		display: flex;
		align-items: stretch;
		background: $lightBlue;
		text-decoration: none;

		&:hover, &:active, &:focus {
			.article-item {
				&__title {
					text-decoration: none;
					color: $red;
					-webkit-text-fill-color: $red;
				}

				&__img {
					img {
						transform: scale(1.07);
					}
				}
			}
		}
	}

	&__img {
		flex: 0 0 15rem;
		overflow: hidden;

		img {
			min-height: 100%;
			max-width: none;
			width: auto;
			transform: scale(1);
			transform-origin: center center;
			transition: transform 0.2s ease-out;
		}
	}

	&__text {
		padding: 1.5rem 1.7rem;
		font-size: 0.9em;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__title {
		font-size: 1rem;
		line-height: 1.5;
		font-weight: 700;
		margin-bottom: 0.7em;
		color: $darkBlue;
		background: none;
		background-clip: border-box;
		display: block;
		-webkit-text-fill-color: $darkBlue;
		text-decoration: underline;
	}

	&__perex {
		margin-bottom: 0;
	}

	&__date	{
		margin-bottom: 0.35rem;
	}
}

@media screen and (max-width: $xs) {
    .article-item {
		&__inner {
			flex-wrap: wrap;
		}

		&__img {
			flex: 0 0 100%;

			img {
				width: 100%;
				height: auto;
			}
		}

		&__text {
			width: 100%;
		}
	}
}