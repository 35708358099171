label { font-family: $baseFont; font-size: 1em; color: $red; font-weight: 700; margin: 0 0 0.5em 0; display: block; }
input.text { font-family: $baseFont; font-size: 1em; color: $red; font-weight: 700; border: none; padding: 0.75em 0.75em; height: 2.5em; width: 100%; }
textarea { font-family: $baseFont; font-size: 1em; color: $red; font-weight: 700; resize: vertical; border: none; padding: 0.75em 0.75em; width: 100%; min-width: 100%; max-width: 100%; height: 8em; min-height: 8em; max-height: 8em; }
select { font-family: $baseFont; font-size: 1em; color: $red; font-weight: 700; width: 100%; padding: 2px 10px; }
option { font-family: $baseFont; font-size: 1em; padding: 2px 20px; }

/* Standard */

.cell,
.cell-input { margin: 0 0 1.3em 0; position: relative; text-align: left; }

/* Inline */

.set-2 .cell { width: 50%; }
.set-3 .cell { width: 33.333333%; }
.set-4 .cell { width: 25%; }
.set-5 .cell { width: 20%; }

.toggle { position: relative; padding: 0 0 0 30px; }
.toggle label { display: inline-block; *display: inline; zoom: 1; vertical-align: top; margin: 0 0 0 0; }
.toggle .note { font-size: 16px; max-width: 100%; }

/* Various */

form.std .radio,
form.std .checker {
  position: absolute;
  left: 0;
  top: 0.01em;
}

/* Form academy */

form.form-academy {
  background: $pink;
  padding: 2em;
}

/* Questions form */

.question-form-text { background: $white; padding: 1.5em 11em 1.5em 1.5em; position: relative; }
.question-form-text .btn { position: absolute; right: 1.6em; top: 50%; @include transform(translateY(-50%)); }
.question-form-box { background: $pink; border: 2px solid $pink; margin-top: 1.5em; margin-bottom: 2em; }
.question-form-box .btn { padding: 0.7em 1.25em; white-space: nowrap; }
.question-form-wrap {  padding: 1.5em; }

.respond-form-wrap { background: $pink; margin-left: 1.5em; @include transform(translateY(-2px)); }
.respond-form-wrap:last-of-type { margin-bottom: 2em; }

/* Contact form */

.contact-form-wrap { background: $pink; padding: 1.25em; }
.contact-form textarea { height: 13.82em; max-height: 13.82em; min-height: 13.82em; }
.contact-form-general textarea { height: 10.05em; max-height: 10.05em; min-height: 10.05em; }

@media screen and (max-width: $xxs) {
  form.form-academy {
    padding: 1em;
  }
}