.tabs { position: relative; }

.tabs .tab-nav ul { letter-spacing: -0.31em; *letter-spacing: normal; *word-spacing: -0.43em; padding: 0; position: relative; }
.tabs .tab-nav li { display: inline-block; *display: inline; zoom: 1; vertical-align: top; letter-spacing: normal; word-spacing: normal; list-style-type: none; padding: 0 0 0 4px; background: none; margin: 0; }
.tabs .tab-nav li a { @extend .btn, .btn-gray; border-radius: 0; width: 100%; text-align: center; }
.tabs .tab-nav li a:hover { background: lighten($darkBlue, 10%); @include box-shadow(0px 9px 20px 0px rgba($darkBlue, 0.6)); }
.tabs .tab-nav li a.selected { background: $red; @include box-shadow(0px 9px 18px 0px rgba($red, 0.35)) }
.tabs .tab-nav li:first-of-type a { border-radius: 2em 0 0 2em; }
.tabs .tab-nav li:last-of-type a { border-radius: 0 2em 2em 0; }

.tabs .tab-content .tab { float: left; width: 100%; background: $white; display: none; padding-top: 1.5em; }
.tabs .tab-content .tab.selected { position: static !important; display: block; }

/* Customers */
.tabs-customers .tab-nav li { width: 25%; }
.tabs-customers .tab-content-img { @include translateX(1.5em); width: auto; }

@media screen and (max-width: $sm) {
	.tabs-customers .tab-content-img { max-height: 20em; margin: 0 auto; @include translateX(0); }
}

@media screen and (max-width: $xs) {
	.tabs .tab-nav li { display: block; width: 100%; padding: 0; }
	.tabs .tab-nav li a { border-radius: 2em !important; }
}
