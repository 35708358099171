$baseFont: "aktiv-grotesk",sans-serif;
$baseFontSize: 20px;
$baseLineHeight: 1.5;
$baseFontWeight: 300;

$baseFontColor: #628090;
$headingColor: #003163;

$darkBlue: #003163;
$lightBlue: #e6ebef;
$grayBlue: #628090;
$btnGrayBlue: #849daa;
$lightGrayBlue: #e9ecf1;
$red: #9c1f00;
$paleRed: #bf7f70;
$green: #23a554;
$blue: #013163;
$pink: #ebd2cc;
$mapBlue: #c1ced3;

$white: #fff;
$black: #000;
$gray: #ddd;

$xlg: 1500px;
$lg: 1400px;
$md: 1199px;
$sm: 991px;
$xs: 767px;
$xxs: 480px;

$baseFontSizeMd: 16px;
$baseFontSizeSm: 15px;
$baseFontSizeXs: 15px;

$headerHeight: 110px;

@mixin textgradient() {
	background: -webkit-linear-gradient(left, $darkBlue 0%, $red 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin transition($transition) {
  	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin scale($scale) {
  	-webkit-transform: scale($scale);
 	-moz-transform: scale($scale);
 	-ms-transform: scale($scale);
 	-o-transform: scale($scale);
 	transform: scale($scale);
}

@mixin translate($translateX,$translateY) {
  	-webkit-transform: translate($translateX,$translateY);
 	-moz-transform: translate($translateX,$translateY);
 	-ms-transform: translate($translateX,$translateY);
 	-o-transform: translate($translateX,$translateY);
 	transform: translate($translateX,$translateY);
}

@mixin translateX($translateX) {
  	-webkit-transform: translateX($translateX);
 	-moz-transform: translateX($translateX);
 	-ms-transform: translateX($translateX);
 	-o-transform: translateX($translateX);
 	transform: translateX($translateX);
}

@mixin translateY($translateY) {
  	-webkit-transform: translateY($translateY);
 	-moz-transform: translateY($translateY);
 	-ms-transform: translateY($translateY);
 	-o-transform: translateY($translateY);
 	transform: translateY($translateY);
}

@mixin rotate($rotate) {
  	-webkit-transform: rotate($rotate);
 	-moz-transform: rotate($rotate);
 	-ms-transform: rotate($rotate);
 	-o-transform: rotate($rotate);
 	transform: rotate($rotate);
}

@mixin transform($transform) {
  	-webkit-transform: $transform;
 	-moz-transform: $transform;
 	-ms-transform: $transform;
 	-o-transform: $transform;
 	transform: $transform;
}

@mixin box-shadow($boxshadow) {
  	-webkit-box-shadow: ($boxshadow);
	-moz-box-shadow: ($boxshadow);
	box-shadow: ($boxshadow);
}