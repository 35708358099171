.guide {}
.guide-form { border-top: 1px solid $red; }
.guide-form-item { border-bottom: 1px solid $red; padding: 1.7rem 1.75rem; }
.guide-form-item .cell-set { display: flex; margin-left: -1.5rem; margin-bottom: -1rem; justify-content: center; }
.guide-form-item .cell-input { margin-left: 1.5rem; margin-bottom: 1rem; }
.guide-form-item .cell-input-image .cell-input { text-align: center; }
.guide-form-item .cell-input-image .toggle { display: inline-block; }
.guide-form-item .cell-image { margin-bottom: 1rem; padding-left: 1.75rem; }
.guide-form-item .cell-image img { margin: 0 auto; }
.guide-form-title { @extend .h3; font-size: 1.3em; text-align: center; margin-bottom: 2rem; width: 100%; }

.guide-products { background: $lightBlue; padding: 2.5rem 1.75rem 2.5rem 1.75rem; margin-top: 1.5rem; }
.guide-products .item-list-product { margin-left: -1.5rem; }
.guide-products .item-product { background: $white; margin-left: 1.5rem; }
.guide-foot { text-align: center; background: $lightBlue; padding: 2rem 1.75rem; border-top: 1px solid darken($lightBlue, 10%); }

@media screen and (max-width: $sm) {
	.guide-form .cell-set { flex-wrap: wrap; }
	.guide-form .cell-input-image { width: 50%; margin-left: 0; }
}

@media screen and (max-width: $xxs) {
	.guide-form .cell-input-image { width: 100%; margin-left: 0; }
}