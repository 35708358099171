.slider ul, .slider li.panel { margin: 0; padding: 0; list-style: none; position: relative; display: block; }
.slider, .slider li.panel { overflow: hidden; background: none; }
.slider .panel:not(:first-of-type) { display: none; }
.slider .panel.slide { display: block; }

.slider-nav { margin-top: 1em; display: block; text-align: center; width: 100%; }
.slider-nav ol { margin: 0 0 0 0; }
.slider-nav li { margin: 0 1.2em 0 0; padding: 0; background: none; display: inline-block; }
.slider-nav li:before { content: none; }
.slider-nav li a { width: 8px; height: 8px; display: block; background: $btnGrayBlue; text-indent: -80000px; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; }
.slider-nav li.current a { background: $darkBlue; }

.slider-nav .prev, .slider-nav .next { width: 40px; height: 40px; margin-top: -80px; top: 50%; overflow: hidden; text-indent: -80000px; display: block; position: absolute; background: url('../img/sprite.png') no-repeat 0 0; cursor: pointer; }

.slider-nav .prev { left: 0; background-position: -16px -180px; }
.slider-nav .prev:hover { background-position: -16px -380px; }
.slider-nav .next { right: 0; background-position: -136px -180px; }
.slider-nav .next:hover { background-position: -136px -380px; }

/* Teaser slider */

.teaser-hp-slider { padding-top: 3.75em; position: relative; /*margin-right: -9em;*/ }
.teaser-hp-slider .slider-nav { position: absolute; left: 0.2em; top: 0; text-align: left }

/* Product slider */

.product-slider-wrap { position: relative; padding-left: 5.5em; padding-right: 1em; }
.product-slider { position: relative; }
.product-slider-nav { position: absolute; left: 0; top: 0; width: 5.5em; }
.product-slider-nav li { display: block; }
.product-slider-link { display: block; margin: 0 0 0.75em 0; width: 5em; height: 5em; border-radius: 50%; overflow: hidden; @include box-shadow(0px 2px 6px 0px rgba($btnGrayBlue, 0.5)); }
.product-slider-link img { max-width: 100%; display: block; max-height: 43em; }

.product-slider-nav-link { display: block; width: 1em; height: 1em; background: url('../svg/src/chevron-right-red.svg') 0 0 no-repeat; background-size: 1em 1em; position: absolute; left: 2em; }
.product-slider-nav-prev { top: -2em;  @include rotate(-90deg); }
.product-slider-nav-next { bottom: -2em; @include rotate(90deg); }

.product-slider-img { max-height: 43em; display: block; margin: 0 auto; }

@media screen and (max-width: $xlg) {
	.teaser-hp-slider { margin-right: -1em; }
}

@media screen and (max-width: $sm) {
	.product-slider { margin-top: 4em; }
	.product-slider-img { max-height: 35em; }
}

@media screen and (max-width: $xxs) {
	.product-slider-img { max-height: 30em; }
}